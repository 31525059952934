import api from "@/services/api";
import { Component } from "vue-property-decorator";
import gridComponentBase from "../components/grid/gridComponentBase.vue";
import { Filter } from "@/models/grid/filter";

const _ENDPONT = api.endpoint.MY_OVERINS.CREDITI_DISTRIBUTORE,
	_ENDPONT_TOTALI = api.endpoint.MY_OVERINS.CREDITI_DISTRIBUTORE_TOTALI;

@Component({})
export default class TuoiCreditiPage extends gridComponentBase {
	aggregates: any[] = [
		{ field: "importo", aggregate: "sum" }
	];
	kendop: any = kendo;
	
	totaleGenerale: number = 0;

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "Num EC", width: 100, type: "numeric-int", filterType: "numeric-int", headerCell: "myHeaderCellTemplate", editHide: true, export: true },

			{ field: "note", title: "Note", headerCell: "myHeaderCellTemplate", export: true },
			{
				field: "importo",
				title: "Importo",
				format: "{0:c}",
				width: 180,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				headerType: "numeric",
				export: true,
				aggregates: ["sum"],
				footerCell: "myFooterImportoCellTemplate"
			},
			{
				field: "istanteInserimento",
				title: "Data Inserimento",
				format: "{0:dd/MM/yyyy HH:mm}",
				width: 150,
				headerCell: "myHeaderCellTemplate",
				type: "date",
				headerType: "daterange",
				export: true
			},
			{ field: "istanteModifica", title: "Ultima Modifica", format: "{0:dd/MM/yyyy HH:mm}", width: 150, headerCell: "myHeaderCellTemplate", type: "date", headerType: "daterange", export: true }
		];
	}

	get columnsMobile(): Array<any> {
		return [
			{ field: "itemID", title: "Num EC", width: 100, type: "numeric-int", filterType: "numeric-int", headerCell: "myHeaderCellTemplate", editHide: true, export: true },
			{
				field: "importo",
				title: "Importo",
				format: "{0:c}",
				width: 180,
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				headerType: "numeric",
				export: true,
				aggregates: ["sum"],
				footerCell: "myFooterImportoCellTemplate"
			},
			{
				field: "istanteInserimento",
				title: "Data Inserimento",
				format: "{0:dd/MM/yyyy HH:mm}",
				width: 150,
				headerCell: "myHeaderCellTemplate",
				type: "date",
				headerType: "daterange",
				export: true
			},
			{ field: "istanteModifica", title: "Ultima Modifica", format: "{0:dd/MM/yyyy HH:mm}", width: 150, headerCell: "myHeaderCellTemplate", type: "date", headerType: "daterange", export: true }
		];
	}

	created() {
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;	
		
		if (!(this.isAppMobile == false)) {
			this.pageable["buttonCount"] = 3;
			this.pageable["info"] = false;
			this.pageable["pageSizes"] = undefined;
		}
	}

	mounted() {
		this.getData();
	}

	exportExcel() {
		this.exportGridExcel(_ENDPONT);
	}

	getData() {
		this.getGridData(_ENDPONT);
		this.calcolaTotaleGenerale();
	}

	getImportoTotale(): number {
		let result = 0;
		
		if (this.itemsGrid && this.itemsGrid.length > 0) {
			let values = this.itemsGrid.map(function (item) {
				return item.importo ? item.importo : 0;
			});

			result = this._.sum(values);
		}

		return result;
	}

	calcolaTotaleGenerale(){
		this.totaleGenerale = 0;
		var self = this;
		api.get(`${_ENDPONT_TOTALI}`).then(res =>{
			self.totaleGenerale = res && res.totale ? res.totale : 0;
		})
	}

	showfilterDialog: boolean = false;

	onApplyFiltersMobile(filtersMobile: Filter) {
		this.filter = filtersMobile;
		this.onFilterMobileApply();
		this.onCloseDialogMessage();
	}
	onRemoveAllFilters() {
		this.clearSortAndFilter();
		//this.onCloseDialogMessage();
	}

	onCloseDialogMessage() {
		this.showfilterDialog = false;
	}

	onOpenFilterDialog() {
		this.showfilterDialog = true;
	}
}
